import { Controller } from '@hotwired/stimulus';
import Highcharts from 'highcharts';
import highchartsMap from 'highcharts/modules/map';
import highchartsAccessibilityInit from 'highcharts/modules/accessibility';
import usStates from '@highcharts/map-collection/countries/us/custom/us-all-territories.topo.json';

export default class extends Controller {
  static targets = ['map'];

  static values = {
    awards: Array,
    states: Array,
  };

  connect() {
    this.drawMap();
  }

  drawMap() {
    highchartsMap(Highcharts);
    highchartsAccessibilityInit(Highcharts);

    Highcharts.mapChart(this.mapTarget, this.mapOptions);
  }

  get mapOptions() {
    // formatting state data to match highcharts state data
    // for join and gives all states included the format with the number 2 i.e. [us-ma, 2]
    // All other states are given the same format but the number 0 - every state needs a number
    // in order to appear on the map -- this filtering process is how we keep the us territories
    // other than PR off of the map -- FYI PR has a slightly different code: pr-3614

    const allStatesButPR = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
    const stateArray = [];
    allStatesButPR.forEach((state) => {
      if (this.statesValue.includes(state)) {
        stateArray.push([`us-${state.toLowerCase()}`, 2]);
      } else {
        stateArray.push([`us-${state.toLowerCase()}`, 0]);
      }
    });

    if (this.statesValue.includes('PR')) {
      stateArray.push(['pr-3614', 2]);
    } else {
      stateArray.push(['pr-3614', 0]);
    }

    return {
      chart: {
        map: usStates,
        height: 500,
        backgroundColor: '',
        animation: false,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: '',
      },
      mapNavigation: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      colorAxis: {
        min: 0,
        dataClasses:
        [
          {
            to: 1,
            color: '#dcdcdc',
          },
          {
            from: 1,
            color: '#00b5ef',
          },
        ],
      },
      series: [{
        data: stateArray,
        name: '',
        nullColor: '#dcdcdc',
        showInLegend: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
        allAreas: false,
        enableMouseTracking: false,
      },
      {
        cursor: 'pointer',
        stickyTracking: false,
        type: 'mappoint',
        name: 'city',
        color: '#f57f29',
        marker: {
          lineWidth: 1,
          lineColor: '#fff',
          symbol: 'circle',
          radius: 8,
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
        keys: ['id', 'awardee', 'city', 'stateAbb', 'locations', 'topic', 'lat', 'lon'],
        data: this.awardsValue,
        events: {
          click(e) {
            const anchor = `#award-${e.target.point.id}`;
            Turbo.visit(anchor); // eslint-disable-line no-undef
          },
        },
      }],
      tooltip: {
        useHTML: true,
        padding: 15,
        formatter: (t) => {
          const awardeeName = `<h4>${t.chart.hoverPoint.awardee}</h4>`;
          const awardeeLocationAndTopic = `${t.chart.hoverPoint.city}, ${t.chart.hoverPoint.stateAbb}<br>Topic ${t.chart.hoverPoint.topic}`;
          const workLocations = `<h5 class="work-locations margin-top-2">Work Locations</h5>${t.chart.hoverPoint.locations}`;
          return awardeeName + awardeeLocationAndTopic + workLocations;
        },
      },
    };
  }
}
